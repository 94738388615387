/* OVERRIDES & GENERAL STYLES */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

.custom-cursor-element {
  cursor: pointer;
}

.custom-transform-element {
  transform: translate(-50%, -50%);
}