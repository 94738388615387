$scrollbar-width: 8px;
$scrollbar-thumb-color: #0078A1;
$scrollbar-hover-color: #555;
$scrollbar-track-color: #f1f1f1;

@mixin scrollStyles {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: 10px;

    &:hover {
      background-color: $scrollbar-hover-color;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }
}

.scroll-bar {
  @include scrollStyles;
}
